//
// Popover
//


// Base
.popover {
    .popover-header {
        font-size: $popover-header-font-size;
        font-weight: $popover-header-font-weight;
        border-bottom: 1px solid $popover-header-border-color;
    }
}
